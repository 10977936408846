// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-bar-link {
    display: flex;
    margin: 10px 10px;
    align-content: center;
    justify-content: center;
    height: 40px;
    width: 120px;
    text-decoration: none;
    color: inherit;
}

.title-bar-link button {
    color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TitleBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".title-bar-link {\n    display: flex;\n    margin: 10px 10px;\n    align-content: center;\n    justify-content: center;\n    height: 40px;\n    width: 120px;\n    text-decoration: none;\n    color: inherit;\n}\n\n.title-bar-link button {\n    color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
