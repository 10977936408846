// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-content {
    display: flex;
    margin: 10px;
    padding: 0;
    justify-content: center;
    height: 95vh;
    width: 95vw;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,UAAU;IACV,uBAAuB;IACvB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".page-content {\n    display: flex;\n    margin: 10px;\n    padding: 0;\n    justify-content: center;\n    height: 95vh;\n    width: 95vw;\n}\n\n.App {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    width: 100vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
