export const navLinkStyles = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    margin: "10px 10px",
    height: "40px",
    width: "120px",
    textDecoration: "none",
    color: "unset",

};