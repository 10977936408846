// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tool-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 100%;
    width: 80%;
}

.tool-list-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    height: 100%;
    width: 100%;
}

.tool-list-item {
    display: flex;
    margin: 10px 10px;
    padding: 10px;
    height: 50px;
    width: 150px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-decoration: none;
    color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ToolPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,qBAAqB;IACrB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;IACrB,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".tool-page-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-content: center;\n    text-align: center;\n    height: 100%;\n    width: 80%;\n}\n\n.tool-list-box {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-evenly;\n    align-content: center;\n    height: 100%;\n    width: 100%;\n}\n\n.tool-list-item {\n    display: flex;\n    margin: 10px 10px;\n    padding: 10px;\n    height: 50px;\n    width: 150px;\n    justify-content: center;\n    align-items: center;\n    align-content: center;\n    text-decoration: none;\n    color: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
